import React, {useRef, useState} from "react"
import axios from 'axios'
import {NormalField, Select} from 'reactstrap-form-fields'
import Layout from "../../components/layout"
import PageHeader from '../../components/pageHeader'
import PageTemplate from '../../components/pageTemplate'
import PageStyling from '../../components/pageStyling'
import { graphql } from 'gatsby'
import ReCAPTCHA from "react-google-recaptcha";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from 'react-google-recaptcha-v3';
import {DynamicSidebar} from '../../components/dynamicSidebar'

let Form = () => {
  let [verify, setVerify] = useState(false)
  return (
    <form
      className="form"
      data-netlify="true"
      method="POST"
      netlify-honeypot="bot-field"
      name="Register With Us"
    >
      <input type="hidden" name="form-name" value="Register With Us"/>
      <NormalField name="First Name"/>
      <NormalField name="Surname"/>
      <NormalField name="Email" type="email"/>
      <NormalField name="Phone" type="integer"/>
      <NormalField name="Australian Postcode" type="integer" required={false}/>
      <NormalField name="DOB" required={false}/>
      <Select fieldType='normal' name="Australian citizen or permanent resident?" options={["Yes", "No"]} required={false}/>
      <NormalField name="Current/Previous automotive employer:" required={false}/>
      <NormalField name="Years of experience (including apprenticeship if applicable)" required={false}/>
      <NormalField name="Qualifications (e.g. Australian certificate 3):" required={false}/>
      <Select fieldType='normal' name="Valid driver’s licence and your own transportation?" options={["Yes", "No"]} required={false}/>
      <NormalField name="Attach CV (not mandatory)" type="file" required={false}/>
      <GoogleReCaptchaProvider reCaptchaKey="6LfkPMEZAAAAABvgtsZCRc36foUfJkf3-x3vUUpN">
        <GoogleReCaptcha onVerify={() => (setVerify(true))}/>
      </GoogleReCaptchaProvider>
      <button className="btn btn-primary mt-3 btn-lg" type="submit" disabled={!verify}>
        SUBMIT
      </button>
    </form>
  )
}

export default ({data}) => (
  <Layout title="REGISTER WITH US">
    <PageHeader title="REGISTER WITH US"/>
    <PageTemplate Sidebar={DynamicSidebar}>
      {data.allWordpressPage.edges[0] ? (
        <>
          <h2>{data.allWordpressPage.edges[0].node.acf.subtitle}</h2>
          <PageStyling dangerouslySetInnerHTML={{ __html: data.allWordpressPage.edges[0].node.content }} />
        </>
      ) : '' }
      <h4>Register With Us</h4>
      <Form/>
    </PageTemplate>
  </Layout>
)

export const pageQuery = graphql`
  query {
    allWordpressPage(filter: {slug: {in: "register-with-us-candidate"}}) {
      edges {
        node {
          title
          content
          acf {
            subtitle
          }
        }
      }
    }
  }
`
